// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getTarget = createAsyncThunk('target/getTarget', async () => {
  const response = await axios.get('/targets')
  return response.data
})

export const getTargetbyMonth = createAsyncThunk('target/getTargetbyMonth', async (date) => {
  const response = await axios.get(`/targets/filter/${date}`)
  return response.data
})

export const getMember = createAsyncThunk('target/getMember', async () => {
  const response = await axios.get('/members/target')
  return response.data
})

export const getMemberbyMonth = createAsyncThunk('target/getMemberbyMonth', async (date) => {
  const response = await axios.get(`/members/target/filter/${date}`)
  return response.data
})

export const getMemberTarget = createAsyncThunk('target/getMemberTarget', async ({ id1, date1 }) => {
  let response
  if (date1 === undefined) {
    response = await axios.get(`/members/target/${id1}`)
  } else {
    response = await axios.get(`/members/target/${id1}/${date1}`)
  }
  
  return response.data
})

export const getTargetTask = createAsyncThunk('target/getTargetTask', async (id) => {
  const response = await axios.get(`/targets/${id}`)
  return response.data
})

export const addTarget = createAsyncThunk(
  'target/addTarget',
  async (target, { dispatch }) => {
    const response = await axios.post('/targets', target)
    if (target.user_id) {
      await dispatch(getMemberTarget({id1: target.user_id}))
    } else {
      await dispatch(getTarget())
    }
    return response.data
  }
)

export const updateTarget = createAsyncThunk(
  'target/updateTarget',
  async (target, { dispatch }) => {
    const response = await axios.put(`/targets/${target.id}`, target)
    if (target.user_id) {
      await dispatch(getMemberTarget({id1: target.user_id}))
    } else {
      await dispatch(getTarget())
    }
    return response.data
  }
)

export const deleteTarget = createAsyncThunk('target/deleteTarget', async ({ id, user_id }, { dispatch }) => {
  const response = await axios.delete(`/targets/${id}`)
  if (user_id) {
    await dispatch(getMemberTarget({id1: user_id}))
  } else {
    await dispatch(getTarget())
  }
  return response.data
})

export const getExport = createAsyncThunk('target/getExport', async (date) => {
  let response
  if (date) {
    response = await axios.get(`/targets/export/filter/${date}`)
  } else {
    response = await axios.get(`/targets/export`)
  }
  return response.data
})

export const getExportMember = createAsyncThunk('target/getExportMember', async ({ id1, date1 }) => {
  let response
  if (date1 === '') {
    response = await axios.get(`/targets/export/${id1}`)
  } else {
    response = await axios.get(`/targets/export/${id1}/${date1}`)
  }
  return response.data
})

export const targetSlice = createSlice({
  name: 'target',
  initialState: {
    targets: [],
    members: [],
    tasks: [],
    exports: [],
    exportMember: [],
    targetOptions: [],
    target_id: null,
    selected: null,
    isLoading: false
  },
  reducers: {
    selectTarget: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: {
    [getTarget.pending]: (state) => {
      state.isLoading = true
    },
    [getTarget.fulfilled]: (state, action) => {
      state.isLoading = false
      state.targets = action.payload
      state.targetOptions = []
      action.payload.map(item => {
        state.targetOptions.push({ value: item.id, label: item.activity })
      })
    },
    [getTargetbyMonth.pending]: (state) => {
      state.isLoading = true
    },
    [getTargetbyMonth.fulfilled]: (state, action) => {
      state.isLoading = false
      state.targets = action.payload
      state.targetOptions = []
      action.payload.map(item => {
        state.targetOptions.push({ value: item.id, label: item.activity })
      })
    },
    [getMember.pending]: (state) => {
      state.isLoading = true
    },
    [getMember.fulfilled]: (state, action) => {
      state.isLoading = false
      state.members = action.payload
    },
    [getMemberbyMonth.pending]: (state) => {
      state.isLoading = true
    },
    [getMemberbyMonth.fulfilled]: (state, action) => {
      state.isLoading = false
      state.members = action.payload
    },
    [getMemberTarget.pending]: (state) => {
      state.isLoading = true
    },
    [getMemberTarget.fulfilled]: (state, action) => {
      state.isLoading = false
      state.targets = action.payload
    },
    [getTargetTask.pending]: (state) => {
      state.isLoading = true
    },
    [getTargetTask.fulfilled]: (state, action) => {
      state.isLoading = false
      state.tasks = action.payload
    },
    [getExport.pending]: (state) => {
      state.isLoading = true
    },
    [getExport.fulfilled]: (state, action) => {
      state.isLoading = false
      state.exports = action.payload
    },
    [getExportMember.pending]: (state) => {
      state.isLoading = true
    },
    [getExportMember.fulfilled]: (state, action) => {
      state.isLoading = false
      state.exportMember = action.payload
    },
    [addTarget.fulfilled]: () => {
      MySwal.fire({
        title: 'Success!',
        text: 'Target has been added',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [addTarget.rejected]: () => {
      MySwal.fire({
        title: 'Failed!',
        text: 'Please check input',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [updateTarget.fulfilled]: () => {
      MySwal.fire({
        title: 'Success!',
        text: 'Target has been updated',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [updateTarget.rejected]: () => {
      MySwal.fire({
        title: 'Failed!',
        text: 'Please check input',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [deleteTarget.fulfilled]: () => {
      MySwal.fire({
        title: 'Success!',
        text: 'Target has been deleted',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [deleteTarget.rejected]: () => {
      MySwal.fire({
        title: 'Failed!',
        text: '',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    }
  }
})
export const { selectTarget } = targetSlice.actions
export default targetSlice.reducer
