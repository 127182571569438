// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getTask = createAsyncThunk('report/getTask', async () => {
  const response = await axios.get('/reports/task') //ganti dg api yg filter tasks berdasarkan board_id dan belum dibuat di tabel report
  return response.data
})

export const getUserTask = createAsyncThunk('report/getUserTask', async () => {
  const response = await axios.get('/reports/claim') ///ganti dg api yg filter berdasarkan board_id dan sudah dibuat data di tabel report
  return response.data
})

export const getReport = createAsyncThunk('report/getReport', async () => {
  const response = await axios.get('/reports')
  return response.data
})

export const getExport = createAsyncThunk('report/getExport', async () => {
  const response = await axios.get('/reports/export')
  return response.data
})

export const addReport = createAsyncThunk(
  'report/addReport',
  async (report, { dispatch }) => {
    const response = await axios.post('/reports', report)
    await dispatch(getTask())
    await dispatch(getReport())
    await dispatch(getExport())
    await dispatch(getUserTask())
    return response.data
  }
)

export const claimTask = createAsyncThunk(
  'report/claimTask',
  async (task, { dispatch }) => {
    const response = await axios.post(`/reports/claim`, task)
    await dispatch(getTask())
    await dispatch(getReport())
    await dispatch(getUserTask())
    return response.data
  }
)

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    tasks: [],
    reports: [],
    exports: [],
    usertasks: [],
    selected: null,
    isLoading: false
  },
  reducers: {
    selectTask: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: {
    [getTask.pending]: (state) => {
      state.isLoading = true
    },
    [getTask.fulfilled]: (state, action) => {
      state.isLoading = false
      state.tasks = action.payload
    },
    [getReport.pending]: (state) => {
      state.isLoading = true
    },
    [getReport.fulfilled]: (state, action) => {
      state.isLoading = false
      state.reports = action.payload
    },
    [getUserTask.pending]: (state) => {
      state.isLoading = true
    },
    [getUserTask.fulfilled]: (state, action) => {
      state.isLoading = false
      state.usertasks = action.payload
    },
    [getExport.pending]: (state) => {
      state.isLoading = true
    },
    [getExport.fulfilled]: (state, action) => {
      state.isLoading = false
      state.exports = action.payload
    },
    [claimTask.fulfilled]: () => {
      MySwal.fire({
        title: 'Success!',
        text: 'Claim Task successful',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [claimTask.rejected]: () => {
      MySwal.fire({
        title: 'Failed!',
        text: 'Claim is failed',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [addReport.fulfilled]: () => {
      MySwal.fire({
        title: 'Success!',
        text: 'Add Report successful',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [addReport.rejected]: () => {
      MySwal.fire({
        title: 'Failed!',
        text: 'Add report is failed',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    }
  }
})
export const { selectTask } = reportSlice.actions
export default reportSlice.reducer
