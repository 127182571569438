// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Fetch Boards
export const getBoards = createAsyncThunk('task/getBoards', async () => {
  const response = await axios.get('/boards')

  return response.data
})

export const getTasks = createAsyncThunk('task/getTasks', async () => {
  const response = await axios.get('/tasks')

  return response.data
})

export const getMember = createAsyncThunk('task/getMember', async () => {
  const response = await axios.get('/members')

  return response.data
})
//ga perlu
export const addBoard = createAsyncThunk('task/addBoard', async (board, { dispatch }) => {
  const response = await axios.post('/boards', board)
  await dispatch(getBoards())
  await dispatch(getTasks())

  return response.data
})

export const addTask = createAsyncThunk('task/addTask', async (task, { dispatch }) => {
  const response = await axios.post('/tasks', task)
  await dispatch(getBoards())
  await dispatch(getTasks())

  return response.data
})

export const updateTask = createAsyncThunk('task/updateTask', async (data, { dispatch }) => {
  const response = await axios.put(`/tasks/${data.id}`, data)
  await dispatch(getBoards())
  await dispatch(getTasks())

  return response.data
})
//belum / kyknya ga perlu
export const clearTasks = createAsyncThunk('task/clearTasks', async (id, { dispatch }) => {
  const response = await axios.delete('/apps/kanban/clear-tasks', { data: id })

  await dispatch(getBoards())
  await dispatch(getTasks())

  return response
})

export const updateTaskBoard = createAsyncThunk('task/updateTaskBoard', async (data, { dispatch }) => {
  const response = await axios.patch(`/tasks/${data.id}`, data)
  await dispatch(getBoards())
  await dispatch(getTasks())

  return response.data
})
//belum
export const reorderTasks = createAsyncThunk('task/reorder-tasks', async (data, { dispatch }) => {
  const response = await axios.post('/apps/kanban/reorder-tasks', { data })
  await dispatch(getBoards())
  await dispatch(getTasks())

  return response.data
})
//ga perlu
export const deleteBoard = createAsyncThunk('task/deleteBoard', async (id, { dispatch }) => {
  const response = await axios.delete('/apps/kanban/delete-board', { data: id })

  await dispatch(getBoards())
  await dispatch(getTasks())

  return response
})
//belum
export const deleteTask = createAsyncThunk('task/deleteTask', async (id, { dispatch }) => {
  const response = await axios.delete(`/tasks/${id}`)

  await dispatch(getBoards())
  await dispatch(getTasks())

  return response
})

export const taskSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasks: [],
    boards: [],
    members: [],
    boardOptions: [],
    selectedTask: null,
    isLoading: false
  },
  reducers: {
    handleSelectTask: (state, action) => {
      state.selectedTask = action.payload
    }
  },
  extraReducers: {
    [getBoards.pending]: (state) => {
      state.isLoading = true
    },
    [getBoards.fulfilled]: (state, action) => {
      state.isLoading = false
      state.boards = action.payload
      state.boardOptions = []
      action.payload.map(item => {
        state.boardOptions.push({ value: item.id, label: item.title })
      })
    },
    [getTasks.pending]: (state) => {
      state.isLoading = true
    },
    [getTasks.fulfilled]: (state, action) => {
      state.isLoading = false
      state.tasks = action.payload
    },
    [getMember.pending]: (state) => {
      state.isLoading = true
    },
    [getMember.fulfilled]: (state, action) => {
      state.isLoading = false
      state.members = action.payload
    }
  }
})

export const { handleSelectTask } = taskSlice.actions

export default taskSlice.reducer
