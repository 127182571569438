// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getTask = createAsyncThunk('dashboard/getTask', async () => {
  const response = await axios.get('/tasks/me') //ganti dg api yg filter tasks berdasarkan assignTo user_id, sort dengan dueDate ASC
  return response.data
})

export const getTargetOverview = createAsyncThunk('dashboard/getTarget', async () => {
  const response = await axios.get('/dashboard/targets')
  return response.data
})

export const getStatistics = createAsyncThunk('dashboard/getStatistics', async () => {
  const response = await axios.get('/dashboard/statistics')
  return response.data
})

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    tasks: [],
    statistics: [],
    targets: [],
    selected: null,
    isLoading: false,
    showTarget: false
  },
  extraReducers: {
    [getTask.pending]: (state) => {
      state.isLoading = true
    },
    [getTask.fulfilled]: (state, action) => {
      state.isLoading = false
      state.tasks = action.payload
    },
    [getTargetOverview.pending]: (state) => {
      state.isLoading = true
      state.showTarget = false
    },
    [getTargetOverview.fulfilled]: (state, action) => {
      state.isLoading = false
      state.showTarget = true
      state.targets = action.payload
    },
    [getStatistics.pending]: (state) => {
      state.isLoading = true
    },
    [getStatistics.fulfilled]: (state, action) => {
      state.isLoading = false
      state.statistics = action.payload
    }
  }
})
export default dashboardSlice.reducer
